<template lang="pug">
.sign
	.sign__title Вход
	//form.sign__form(
	//	method="post"
	//	@submit.prevent="validate"
	//)
	form.sign__form(
		method="post"
		@submit.prevent="signin"
	)
		.sign__item
			Input(
				placeholder="Эл. почта"
				v-model="form.email"
				autocomplete="disabled"
				)
		.sign__item
			InputPassword(
				placeholder="Пароль"
				type="password"
				v-model="form.password"
				autocomplete="disabled"
				)
			.sign__sub(@click="forgotPass") Забыли пароль?
		.sign__btn
			//VueRecaptcha(
			//	ref="recaptcha"
			//	size="invisible"
			//	:sitekey="sitekey"
			//	@verify="signin"
			//	@expired="onCaptchaExpired"
			//)
			button(type="submit")
				Button(:disabled="isEmpty") Войти
</template>

<script>
import Input from '@/components/Elements/Input.vue'
import InputPassword from '@/components/Elements/InputPassword.vue'
import Button from '@/components/Button/Button.vue'
// import VueRecaptcha from 'vue-recaptcha'
import { mapActions } from 'vuex'

export default {
	components: {
		Input,
		InputPassword,
		Button,
		// VueRecaptcha,
	},
	data() {
		return {
			form: {
				email: '',
				password: '',
			},
			sitekey: '6LfPuBwdAAAAACG_x3i-gzS4p_yjrjfX0B1jfgMa',
		}
	},
	computed: {
		isEmpty() {
			return !this.form.email || !this.form.password
		}
	},
	methods: {
		...mapActions('app', ['login']),
		validate() {
			this.$refs.recaptcha.execute()
		},
		// async signin(recaptchaToken) {
		async signin() {
			try {
				// let params = { ...this.form, recaptchaToken}
				let params = this.form
				const { success } = await this.login(params)
				if (success) {
					location.href = '/purina'
					this.$toast.success('Success', {
						position: "bottom-right",
						timeout: 3000,
						closeOnClick: true,
						hideProgressBar: true,
						closeButton: "button",
					});
					// Аналитика. Событие успешной авторизации
					// Google Analitycs GTAG
					// [ ] Подключить аналитику, когда будет ID счетчика
					// window.gtag('event', 'auth_success', {
					// 	email: this.form.email,
					// })
					// [ ] Подключить метрику, когда будет ID счетчика
					// Yandex metrics
					// window.ym(90017460,'reachGoal','auth_success', {
					// 	email: this.form.email,
					// })
				}
			} catch (error) {
				const result = Object.prototype.hasOwnProperty.call(error.data, 'message') ?
					error.data.message : null
				const textError = result === 'Bad Request' || result === 'Unauthorized' ? 'Неверный email или пароль' : ''
				this.$toast.error(textError, {
					position: "bottom-right",
					timeout: 3000,
					closeOnClick: true,
					hideProgressBar: true,
					closeButton: "button",
				});
				// Аналитика. Событие неуспешной авторизации
				// Google Analitycs GTAG
				// [ ] Подключить аналитику, когда будет ID счетчика
				// window.gtag('event', 'auth_unsuccess', {
				// 	email: this.form.email,
				// })
				// Yandex metrics
				// [ ] Подключить метрику, когда будет ID счетчика
				// window.ym(90017460,'reachGoal','auth_unsuccess', {
				// 	email: this.form.email,
				// })
			}
		},
		forgotPass() {
			console.log('forgotPass')
		},
		onCaptchaExpired () {
			this.$refs.recaptcha.reset()
		}
	}
}
</script>

<style lang="scss" scoped>
.sign {
	width: 100%;

	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 1.35;
	}
	&__form {
		margin-top: 20px;
	}
	&__item {
		margin-top: 26px;

		input {
			width: 100%;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&.--invalid {
			::v-deep .input {
				border: 1px solid color(orange);
			}
		}
	}
	&__sub {
		margin-top: 10px;
		color: color(violet);
		font-size: 12px;
		line-height: 1.33;
		text-align: right;
		cursor: pointer;
	}
	&__btn {
		margin-top: 5px;
	}
}
</style>
